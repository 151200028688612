const heroCarousel = () => {
  let manualMode = false;
  let manualTimeout = null;

  const getNodes = (root) => root.querySelectorAll('.hero-section');
  const getMarkers = (root) => root.querySelectorAll('.pagination > svg');
  const getActiveIdx = (root) => Array.from(getNodes(root)).findIndex((n) => n.classList.contains('active'));
  const setNode = (root, nextIdx) => {
    const nodes = getNodes(root);
    const markers = getMarkers(root);
    const lastIdx = nodes.length - 1;
    const idx = nextIdx < 0 ? lastIdx : nextIdx > lastIdx ? 0 : nextIdx;

    nodes.forEach((n, i) => n.classList.toggle('active', i === idx));
    markers.forEach((m, i) => m.classList.toggle('active', i === idx));
  };

  const incNode = (root, inc) => setNode(root, getActiveIdx(root) + inc);

  const manual = (root) => {
    if (manualTimeout) {
      clearTimeout(manualTimeout);
    }

    manualMode = true;
    manualTimeout = setTimeout(() => manualMode = false, 30000);
  };

  const schedule = (root) => setTimeout(() => {
    if (!manualMode) {
      incNode(root, 1);
    }

    schedule(root);
  }, 7000);

  const roots = document.querySelectorAll('.hero-carousel');
  roots.forEach((root) => {
    getMarkers(root).forEach((m, idx) => m.addEventListener('click', (e) => {
      e.preventDefault();
      setNode(root, idx);
      manual(root);
    }));

    root.querySelectorAll('.carousel-pager').forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        incNode(root, btn.classList.contains('next') ? 1 : -1);
        manual(root);
      });
    });

    schedule(root);
  });
};

export default heroCarousel;
