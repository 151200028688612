import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import lottiePlayer from 'lottie-web';

const Lottie = ({
  className,
  animationData,
  autoplay,
  loop,
  name,
  path,
  randomized,
  minDelay,
  maxDelay,
  renderer,
}) => {
  const ref = useRef();
  const anim = useRef();
  const timeout = useRef();

  const onAnimationTimeout = useCallback(() => {
    if (!anim.current) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    anim.current.stop();
    const delay = minDelay + Math.floor(Math.random() * (maxDelay - minDelay));
    timeout.current = setTimeout(() => anim.current.play(), delay);
  }, [anim, timeout, minDelay, maxDelay]);

  const onAnimationComplete = useCallback(() => {
    if (randomized) {
      onAnimationTimeout();
    }
  }, [randomized, onAnimationTimeout]);

  useEffect(() => {
    if (!anim.current) {
      anim.current = lottiePlayer.loadAnimation({
        container: ref.current,
        animationData,
        autoplay: !randomized && autoplay,
        loop: !randomized && loop,
        name,
        path,
        renderer,
      });

      anim.current.addEventListener('complete', onAnimationComplete);
    }

    return () => {
      if (anim.current) {
        anim.current.removeEventListener('complete', onAnimationComplete);
        anim.current.destroy();
      }

      anim.current = null;
    };
  }, [animationData, path, onAnimationComplete]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (randomized) {
      onAnimationTimeout();
    }

    return () => timeout.current ? clearTimeout(timeout.current) : void 0;
  }, [timeout, randomized]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={className}
      ref={ref}
    />
  );
};

Lottie.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  animationData: PropTypes.object,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
  name: PropTypes.string,
  path: PropTypes.string,
  randomized: PropTypes.bool,
  minDelay: PropTypes.number,
  maxDelay: PropTypes.number,
  renderer: PropTypes.oneOf([
    'svg',
    'canvas',
    'html',
  ]),
};

Lottie.defaultProps = {
  className: undefined,
  autoplay: true,
  loop: true,
  name: undefined,
  path: undefined,
  randomized: false,
  minDelay: 7 * 1000,
  maxDelay: 20 * 1000,
  renderer: 'svg',
};

export default Lottie;
