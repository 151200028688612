import React, { useEffect, useState } from 'react';
import Lottie from './Lottie';

const Glitter = ({
  name,
}) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    import(
      /* webpackChunkName: "glitter" */
      /* webpackMode: "lazy" */
      `../animations/promo-${name}-glitter.json`
    ).then((module) => setAnimationData(module));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Lottie animationData={animationData} />
  );
};

export default Glitter;
