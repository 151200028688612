import { mountComponents } from '../lib/react';
import { mountEnhancements } from '../lib/enhancements';

// When adding new components or enhancements, do we also need
// to add them to premium/homepage.js? Please be sure to check!

mountComponents({
  'Glitter': require('../components/Glitter'),
});

mountEnhancements({
  'heroCarousel': require('../enhancements/heroCarousel'),
});
